<template>
  <div v-if="editStatus">
    <Modal v-model="editStatus" title="企业认证" width="700" @on-ok="commitData" @on-cancel="close" :loading="subLoading" :closable="false">
      <Form label-position="left" :label-width="90" ref="fValidate" :model="editData" :rules="ruleValid" >
        <div v-viewer>
          <FormItem label="营业执照" style="margin-bottom: 10px" prop="img">
            <div class="img-item">
              <div class="img-comp" v-for="(item,key) in editData.img1" :key="key">
                <div class="img-m">
                  <img class="img" v-lazy="item"  alt="点击放大" title="点击放大">
                  <a href="javascript:;" class="btn-close iconfont" @click="delImg('img1',key)">&#xe7fd;</a>
                </div>
              </div>
              <div class="up-btn-comp" v-if="editData.img1.length < 3">
                <upload action="upLoadCompany" imgName="img1" @upSuccess="upSuccess" @upProgress="upProgress">
                  <div slot="btn_view" title="点击上传" alt="点击上传" class="img-btn img-btn-b"><Icon type="ios-add" size="72" color="#dddddd" /></div>
                </upload>
                <Spin fix v-if="progress.percent_img1!=-1">
                  <Icon type="ios-loading" size=18 class="spin-icon-load"></Icon>
                  <div>上传中{{progress.percent_img1}}%</div>
                </Spin>
              </div>
            </div>
          </FormItem>
          <FormItem label="开户许可证" style="margin-bottom: 10px" prop="img">
            <div class="img-item">
              <div class="img-comp" v-for="(item,key) in editData.img2" :key="key">
                <div class="img-m">
                  <img class="img" v-lazy="item" alt="点击放大" title="点击放大" >
                  <a href="javascript:;" class="btn-close iconfont" @click="delImg('img2',key)">&#xe7fd;</a>
                </div>
              </div>
              <div class="up-btn-comp" v-if="editData.img2.length < 3">
                <upload action="upLoadCompany" imgName="img2" @upSuccess="upSuccess" @upProgress="upProgress">
                  <div slot="btn_view" title="点击上传" alt="点击上传" class="img-btn img-btn-t"><Icon type="ios-add" size="72" color="#dddddd" /></div>
                </upload>
                <Spin fix v-if="progress.percent_img2!=-1">
                  <Icon type="ios-loading" size=18 class="spin-icon-load"></Icon>
                  <div>上传中{{progress.percent_img2}}%</div>
                </Spin>
              </div>
            </div>
          </FormItem>
        </div>
        <FormItem label="公司名称" style="margin-bottom: 10px" prop="cname">
          <Input v-model="editData.cname" maxlength="30"></Input>
        </FormItem>
        <FormItem label="企业法人" style="margin-bottom: 10px" prop="cman">
          <Input v-model="editData.cman" maxlength="10"></Input>
        </FormItem>
        <FormItem label="营业执照号" style="margin-bottom: 10px" prop="cnum">
          <Input v-model="editData.cnum" maxlength="30"></Input>
        </FormItem>
        <FormItem label="开户银行" style="margin-bottom: 10px" prop="cbank">
          <Input v-model="editData.cbank" maxlength="30"></Input>
        </FormItem>
        <FormItem label="银行账号" style="margin-bottom: 10px" prop="cbank_num">
          <Input v-model="editData.cbank_num" maxlength="30"></Input>
        </FormItem>
        <FormItem label="企业支付宝" style="margin-bottom: 10px">
          <Input v-model="editData.calipay" maxlength="50"></Input>
        </FormItem>
        <FormItem label="认证状态" style="margin-bottom: 10px">
          <RadioGroup v-model="editData.status">
            <Radio :label="1">认证通过</Radio>
            <Radio :label="0">认证不通过</Radio>
          </RadioGroup>
        </FormItem>
        <FormItem label="认证备注" style="margin-bottom: 10px">
          <Input v-model="editData.msg" type="textarea" :autosize="{minRows: 2,maxRows: 2}" placeholder="认证审核备注"></Input>
        </FormItem>
      </Form>
    </Modal>
  </div>
</template>

<script>
import upload from "@/components/public/upload";
export default {
  name:'edit_company',
  components:{
    upload
  },
  data(){
    return {
      progress:{
        'percent_img1':-1,
        'percent_img2':-1,
      },
      editStatus:false,
      subLoading:true,
      editData:{
        img1:[],
        img2:[]
      },
      ruleValid:{
        cname: [
          { required: true, message: '此项必填', trigger: 'blur' }
        ],
        cman: [
          { required: true, message: '此项必填', trigger: 'blur' }
        ],
        cnum: [
          { required: true, message: '此项必填', trigger: 'blur' }
        ],
        cbank: [
          { required: true, message: '此项必填', trigger: 'blur' }
        ],
        cbank_num: [
          { required: true, message: '此项必填', trigger: 'blur' }
        ]
      }
    }
  },
  watch:{
    subLoading(e){
      if(!e)
        setTimeout(()=>{
          this.subLoading = true;
        },50)
    }
  },
  methods:{
    open:function(editData){
      editData = JSON.parse(JSON.stringify(editData))
      editData.img1 = JSON.parse(editData.img1);
      editData.img2 = JSON.parse(editData.img2);
      this.editStatus = true
      setTimeout(()=>{
        this.$set(this,'editData',editData);
      },50)
    },
    commitData:function(){
      let _this = this;
      this.$refs.fValidate.validate((valid) => {
        if (valid) {
          this.requestApi('/adm/comCompany.html', {editData:this.editData}).then(function (res){
            _this.subLoading = false;
            if(res.status == 1){
              _this.$Message.success(res.msg);
              _this.$emit('saveOk', {reData:res.data});
              _this.editStatus = false;
            }else{
              _this.$Message.error(res.msg);
            }
          })
        }else{
          this.subLoading = false;
          this.$Message.error('数据未完善!');
        }
      })
    },
    delImg:function(imgName,index){
      this.$Modal.confirm({
        title: '提示',
        content: '确定要删除该图片吗？',
        onOk: () => {
          this.editData[imgName].splice(index,1)
        }
      });
    },
    upProgress:function(e){
      this.progress['percent_'+e.name] = e.percent;
    },
    upSuccess:function(e){
      this.editData[e.name].push(e.response.url);
    },
    close:function(){
      this.editStatus = false;
    }
  }
}
</script>

<style lang="less">
@import "~@/assets/css/user_auth/edit_company";
</style>